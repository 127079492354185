import * as React from "react";
import { graphql } from "gatsby";

export default function IndexPage({ data }) {
  const title = data.site.siteMetadata.title;
  return (
    <main>
      <h1>
        {title}
        <br />
        <span>{data.site.siteMetadata.description}</span>
        <ul>
          {data.allSocialLinksJson.edges.map(({ node }) => (
            <li>
              <a href={node.uri}>{node.name}</a>
            </li>
          ))}
        </ul>
      </h1>
    </main>
  );
}

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allSocialLinksJson {
      edges {
        node {
          uri
          name
        }
      }
    }
  }
`;
